<cl-field-wrapper
  #componentWrapper
  id="samples"
  class="autocomplete-wrapper"
  [loading]="searching"
  [required]="true"
  (lostFocus)="handleFocusOut($event)"
  [errors]="errors"
  [searcher]="true"
  [microText]="microText"
  [label]="'LABELS.SAMPLES' | translate">
  <input
    #input
    clSelectSelf
    [placeholder]="placeholder"
    [(ngModel)]="displayValue"
    (blur)="handleBlur($event)"
    (dblclick)="toggleEdit()"
    (keydown.backspace)="handleBackspace()"
    (keydown.arrowRight)="handleRightArrow($event)"
    (keydown.arrowDown)="onArrowDown($event)"
    (keydown.escape)="handleEscape($event)"
    class="spot-form__input"
    spellcheck="false"
    autocomplete="new-item" />
  <div [hidden]="!displayedData?.length" class="search-results-wrapper">
    <ng-container *ngFor="let match of displayedData; let index = index; let first = first">
      <div class="results-list-item-wrapper" luxLayout="row">
        <button
          class="result-list-item"
          [class]="first ? 'canSelectAttributes' : ''"
          [class.selection]="first"
          id="{{ 'newSample' + match.code }}"
          data-cy="autocomplete-item"
          type="button"
          (click)="handleClick($event, match)">
          <span class="content" *ngIf="match.label">
            <span class="short-code" *ngIf="match.shortCode">{{ match.shortCode }}&nbsp;-&nbsp;</span>
            {{ match.name }}
          </span>
        </button>
        <div *ngIf="first" class="new-sample-attributes-trigger-wrapper">
          <cl-sample-attributes
            #newSampleAttributesFlyout
            [sample]="createSampleFromMatch(match)"
            [sampleAttributeIdPrefix]="'newSample'"
            [showEditTrigger]="false"
            (attributesAssigned)="handleAttributeAssignment($event)"
            (newSampleAttributesFlyoutClosedEvent)="
              handleNewSampleAttributesFlyoutClosedEvent($event)
            "></cl-sample-attributes>
        </div>
      </div>
    </ng-container>
  </div>
</cl-field-wrapper>

<div *ngIf="value && value.samples.length > 0">
  <div class="added-sample-container" *ngFor="let item of value.samples; let index = index; let first = first">
    <!--suppress TypeScriptUnresolvedReference -->
    <cl-pill
      class="added-sample"
      id="{{ 'addedSample' + item.code }}"
      [type]="item.sampleTypeStatus === SampleTypeStatus.INACTIVE ? 'inactive' : item.displayType || 'leopard-box'"
      [removable]="edit && !item.isRemoved && item.status !== 'REMOVED'"
      [canUndoRemove]="edit && item.isRemoved && item.status !== 'REMOVED'"
      (dblclick)="toggleEdit()"
      (remove)="remove(item, $event)"
      (unremove)="unremove(item)"
      [ngClass]="{
        'display-as-vertical-list': displayAsVerticalList,
        'inactive-sample-type': item.sampleTypeStatus === SampleTypeStatus.INACTIVE,
      }">
      <span [class]="item.isRemoved || item.status === 'REMOVED' ? 'removed' : ''" class="label-wrapper">
        <span *ngIf="item.sampleTypeStatus === SampleTypeStatus.INACTIVE">
          {{ 'ERRORS_AND_FEEDBACK.INACTIVE' | translate }}
        </span>
        <span *ngIf="item['shortCode']">
          <span class="short-code-selected">{{ item.shortCode }}</span>
          &nbsp;-&nbsp;
        </span>
        {{ item.name }}

        <ng-container class="item-attribute collection-method" *ngIf="item.collectionMethod?.name">
          {{ '(' + item.collectionMethod.name + ')' }}
        </ng-container>

        <ng-container class="item-attribute draw" *ngIf="item.draw?.name">
          {{ '(' + item.draw.name + ')' }}
        </ng-container>

        <ng-container class="item-attribute temperature" *ngIf="item.temperature?.name">
          {{ '(' + item.temperature.name + ')' }}
        </ng-container>

        <ng-container class="item-attribute" *ngIf="item?.drawTime">
          <ng-container *ngIf="item.drawTime.interval">
            <span class="draw-time">
              ({{ item.drawTime.interval.time + (item.drawTime.interval.unit === 'MINUTES' ? minUnit : hourUnit) }})
            </span>
          </ng-container>
          <ng-container *ngIf="item.drawTime.time">
            <span class="draw-time">({{ item.drawTime.time }})</span>
          </ng-container>
        </ng-container>
      </span>

      <cl-sample-attributes
        *ngIf="edit && !item['isRemoved'] && item.status !== 'REMOVED'"
        [sample]="item"
        [sampleAttributeIdPrefix]="'addedSample'"
        (attributesAssigned)="handleAttributeAssignment($event)"></cl-sample-attributes>
    </cl-pill>
  </div>
</div>
