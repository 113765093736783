import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Sample, SampleFormValue, SampleTypeStatus } from '../../models/sample.model';
import { OrderEntryService } from '../../../order-entry/order-entry.service';
import { SearchSelectComponent } from '../search-select/search-select.component';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { KeyboardService } from '@lims-common-ux/lux';
import { SampleAttributesComponent } from '../sample-attributes/sample-attributes.component';
import { DomUtil } from '../../utils';

type SampleValue = Sample | SampleFormValue | string;
const isSample = (value: SampleValue): value is Sample => (value as Sample)?.code !== undefined;
const isSampleFormValue = (value: SampleValue): value is SampleFormValue =>
  (value as SampleFormValue)?.samples !== undefined;

@Component({
  selector: 'cl-samples',
  templateUrl: './samples.component.html',
  styleUrls: ['../search-select/search-select.component.scss', './samples.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SamplesComponent,
      multi: true,
    },
  ],
})
export class SamplesComponent extends SearchSelectComponent<Sample> implements ControlValueAccessor, AfterContentInit {
  @ViewChild('input')
  input!: ElementRef;
  @ViewChild('newSampleAttributesFlyout')
  newSampleAttributesFlyout!: SampleAttributesComponent;
  control: AbstractControl = null;

  @Input()
  isExistingAccession: boolean;
  @Input()
  searchUrl: string;
  @Input()
  operationalRegionCode: string;
  @Input()
  sortByCode: boolean = true; // Sort by code is the default for all regions EXCEPT Central Europe
  @Input()
  displayAsVerticalList: boolean;

  // update event does not fire on missing info glyph
  @Output()
  update = new EventEmitter<SampleFormValue>();

  // when this is true we will auto select the first returned item, and move to the next field
  autoselectShortCodeMatch = false;
  // when in edit mode assign tests can be deleted, and the delete icon will be visible
  edit = false;
  hotKeyInstructionMicroText = 'HOT_KEY_INSTRUCTIONS.EDIT';
  microText = [];
  missingInformationGlyph = this.orderEntryService.missingInformationGlyph;
  missingInfoValueWarning = 'ERRORS_AND_FEEDBACK.INVALID_MISSING_INFORMATION_SAMPLES';
  // used track when search data is going to be waiting on a time based interaction (server-calls)
  searching = false;
  value: SampleFormValue = {
    samples: [],
    missingSample: false,
  };

  flyoutClosedEvent: boolean;
  minUnit = this.translate.instant('LABELS.DRAW_TIME_MINUTE_UNIT');
  hourUnit = this.translate.instant('LABELS.DRAW_TIME_HOUR_UNIT');

  @HostListener('keydown.alt.e', ['$event'])
  onAltE(event) {
    this.stopEvent(event);
    this.toggleEdit();
  }

  ngAfterContentInit(): void {
    const model = this.injector.get(NgControl);
    this.control = model.control;
  }

  // Open sample attributes when adding a new sample
  handleRightArrow(event) {
    if (!this.newSampleAttributesFlyout?.showAttributes) {
      this.stopEvent(event);
      this.newSampleAttributesFlyout?.toggleShowAttributes();
    }
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  sortSamples = (items: Sample[]) => {
    if (this.sortByCode) {
      // SORT/GROUP SAMPLES BY CODE TO ASSIST VISUAL INVENTORY
      // Note: Non-Central Europe regions only. Default is true, Central Europe configuration defined in region template
      const originalInstances = [];
      items.forEach((item, index) => {
        let found = false;
        originalInstances.forEach((instance) => {
          if (instance.code === item.code) {
            return (found = true);
          }
        });
        if (!found) {
          originalInstances.push({
            code: item.code,
            index: index,
          });
        }
      });
      // For each original instance of a code, find each dupe, with index
      if (originalInstances && originalInstances.length >= 2) {
        originalInstances.forEach((originalInstance) => {
          items.forEach((item, index) => {
            if (
              item.code === originalInstance.code &&
              index !== originalInstance['index'] &&
              index !== originalInstance['index'] + 1
            ) {
              // Splice each dupe after each original instance
              items.splice(originalInstance['index'] + 1, 0, items.splice(index, 1)[0]);
            }
          });
        });
      }
    }
    return items;
  };

  constructor(
    private orderEntryService: OrderEntryService,
    public translate: TranslateService,
    private keyboardService: KeyboardService,
    private injector: Injector
  ) {
    super();
  }

  handleSearch(value: string) {
    this.hideOptions();

    if (!value) {
      this.searching = false;
      this.autoselectShortCodeMatch = false;

      // When the missing info glyph is deleted from the input, revalidate the field
      if (this.value.missingSample && value !== this.missingInformationGlyph) {
        this.value.missingSample = false;
        this.onChange(this.value);
      } else {
        this.updateMicroText();
      }

      return;
    }

    if (value !== this.missingInformationGlyph) {
      this.searching = true;

      if (!this.value.missingSample) {
        this.control.updateValueAndValidity({ emitEvent: false });
      }

      super.filter(value);

      if (this.displayValue) {
        this.searching = false;
      }

      if (this.displayedData?.length < 1) {
        // Fire onChange to validate field and show error when no matches are found
        this.control.updateValueAndValidity({ emitEvent: false });
      }

      this.handleIncompleteInteraction();
    } else if (value === this.missingInformationGlyph) {
      this.autoselectShortCodeMatch = false;
      // we do NOT write '?' to the component if there have been samples added
      if (this.value.samples.length < 1 || this.allSamplesAreRemoved()) {
        this.onValueChange(value);

        setTimeout(() => {
          this.goToNext.next(true);
        });
      }
      this.updateMicroText();
    }
  }

  writeValue(value: SampleFormValue) {
    this.value = value;
    if (value.missingSample) {
      this.displayValue = this.missingInformationGlyph;
    }

    this.updateMicroText();
  }

  // A Sample, Sample[], or the missing information glyph string can be written
  onValueChange(value: SampleValue | string, emitEvent = true) {
    if (isSample(value)) {
      // Add a single sample
      this.value.missingSample = false;

      const newSample = new Sample(value);
      newSample.setId();

      // Add a Sample to the order
      const updateValue = this.value;
      updateValue.samples.push(newSample);

      this.value.samples = this.sortByCode ? this.sortSamples(updateValue.samples) : updateValue.samples;

      requestAnimationFrame(() => {
        this.resetInputValue();
        this.focusInput();
      });
    } else if (isSampleFormValue(value)) {
      // Add multiple samples (e.g. Edit accession load) or missing samples
      this.value = value;
      if (value.missingSample) {
        this.displayValue = this.missingInformationGlyph;
      }
    } else if (value && typeof value === 'string') {
      this.value.missingSample = true;
    } else {
      this.value.samples = [];
      this.value.missingSample = false;
    }

    if (emitEvent) {
      this.onChange(this.value);
    }

    if (!this.value.samples.length || this.value.missingSample) {
      this.edit = false;
    }

    this.updateMicroText();
  }

  handleUpdateChanges() {
    requestAnimationFrame(() => {
      this.onChange(this.value);

      this.updateMicroText();
    });
  }

  unremove(sample: Sample) {
    if (!this.edit) {
      return false;
    }

    this.focusInput();

    sample.isRemoved = false;

    // Reset the input when unremoving a sample when a user has already entered the missing information glyph
    if (!this.allSamplesAreRemoved() && this.input.nativeElement.value !== '') {
      this.resetInputValue();
      this.value.missingSample = false;
    }

    this.handleUpdateChanges();
  }

  remove(sample: Sample, event?) {
    if (!this.edit) {
      return false;
    }

    const orderSamples = (this.value.samples || []).slice();
    const index: number = orderSamples.indexOf(sample, 0);

    if (sample.saved) {
      this.focusInput();

      sample.isRemoved = true;

      this.handleUpdateChanges();
    } else {
      // @ts-ignore
      this.value.samples = this.value.samples.filter((existingSample) => {
        return existingSample !== sample;
      });

      // When removing a value via keyboard, set focus to the next available editable item.
      // When removing a value via mouse, set focus to the component input.
      // Focus rules are tested in acceptance tests.
      if (event?.pointerId) {
        const focusableParent = DomUtil.queryUp(event.target, 'a');
        // @ts-ignore
        focusableParent?.focus();
      }
      this.focusNextElement(index);
    }
  }

  focusNextElement(index: number) {
    // Focus the next available, focusable element in the list, or the component input if no list items are left
    if (this.value?.samples.length > 1 && index !== this.value?.samples.length) {
      this.keyboardService.focusNext();
    } else if (this.value?.samples.length !== 0) {
      this.keyboardService.focusPrev();
      // Updating an accession and attempting to remove the last and only removable item
      this.keyboardService.focusPrev();
    } else {
      this.focusInput();
    }

    // When there are no remaining items, toggle edit mode to false
    if (!this.value.samples.length) {
      this.edit = false;
    }

    this.handleUpdateChanges();
  }

  // Invalidate input if the user leaves the input after initiating search, but before selecting a value
  handleIncompleteInteraction() {
    requestAnimationFrame(() => {
      if (!this.isInputActiveElement() && !this.value) {
        this.onValueChange(null);
        this.autoselectShortCodeMatch = false;
        this.hideOptions();
      }
    });
  }

  createSampleFromMatch(match): Sample {
    const sampleFromMatch = Sample.createFromReferenceData(match);
    return sampleFromMatch;
  }

  handleAttributeAssignment(updatedSample) {
    const found = this.value.samples.filter((sample) => sample.id === updatedSample.id)[0];

    if (!found) {
      super.selectItem(updatedSample);
    } else {
      this.onChange(this.value);
    }

    this.flyoutClosedEvent = false;
  }

  handleNewSampleAttributesFlyoutClosedEvent($event) {
    if (this.displayedData?.length > 0) {
      this.flyoutClosedEvent = true;
    }
  }

  stopEvent(event: MouseEvent | KeyboardEvent) {
    event.preventDefault();
    event.stopImmediatePropagation();
  }

  toggleEdit() {
    this.edit = !this.edit;

    if (!this.edit) {
      this.focusInput();
    }
  }

  focusInput() {
    this.input?.nativeElement.focus();
  }

  handleBlur($event) {
    this.onTouched();
    if (!this.newSampleAttributesFlyout?.showAttributes) {
      this.control.updateValueAndValidity({ emitEvent: false });
    } else {
      this.stopEvent($event);
    }
  }

  handleBackspace() {
    setTimeout(() => {
      this.control.updateValueAndValidity({ emitEvent: false });
    });
  }

  handleFocusOut($event) {
    if (!this.flyoutClosedEvent) {
      super.handleFocusOut($event);

      if (!this.value.samples.length) {
        this.control.updateValueAndValidity({ emitEvent: false });
      }
    } else {
      this.flyoutClosedEvent = false;
      this.input.nativeElement.focus();
    }
  }

  handleEnter(event) {
    if (this.displayedData.length && !this.flyoutClosedEvent) {
      this.selectItem(this.displayedData[0]);
    } else if (!this.displayedData.length && this.displayValue && this.searching) {
      this.autoselectShortCodeMatch = true;
    } else if (!this.displayedData.length && !this.flyoutClosedEvent && !this.input.nativeElement.value) {
      if (event.target.getAttribute('autocomplete')) {
        super.filter();
      }
    }
  }

  handleEscape($event) {
    if (this.displayedData?.length > 0) {
      this.stopEvent($event);
      this.edit = false;
      this.hideOptions();
      this.focusInput();
    }
  }

  allSamplesAreRemoved(): boolean {
    let allSamplesRemoved = false;
    allSamplesRemoved =
      this.value.samples.filter((sample) => sample.isRemoved || sample.status === 'REMOVED').length ===
      this.value.samples.length;
    return allSamplesRemoved;
  }

  updateMicroText() {
    const newTextArr: string[] = [];

    // we have a test and a missing info glyph has been entered
    if (
      this.value.samples.length &&
      this.displayValue === this.missingInformationGlyph &&
      !this.allSamplesAreRemoved()
    ) {
      this.translate
        .get(this.missingInfoValueWarning, {
          value: this.missingInformationGlyph,
        })
        .subscribe((i18nVal) => {
          newTextArr.push(i18nVal);
        });
    }

    // we have a Sample so show the edit microText
    if (this.value.samples.length) {
      newTextArr.push(this.hotKeyInstructionMicroText);
    }

    this.microText = newTextArr;
  }

  /* CVA BOILERPLATE */
  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouch: any) {
    this.onTouched = onTouch;
  }
  protected readonly SampleTypeStatus = SampleTypeStatus;
}
