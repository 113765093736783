import { Component, ElementRef, forwardRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DateFormComponent } from '../date-form/date-form.component';
import { DateTimeUtil, StringUtil } from '../../utils';
import { DateFormService } from '../date-form/date-form.service';
import { PatientAgeDOB } from './age-dob.model';
import { LocaleService, PatientAge } from '@lims-common-ux/lux';

@Component({
  selector: 'cl-age-dob',
  templateUrl: './age-dob.component.html',
  styleUrls: ['./age-dob.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AgeDobComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AgeDobComponent),
      multi: true,
    },
  ],
})
export class AgeDobComponent implements OnInit, ControlValueAccessor, Validator, OnDestroy {
  @Input()
  boundaryYears: number = 300;

  @ViewChild('ageInput')
  ageInput!: ElementRef;

  @ViewChild('dobEntryForm')
  dobEntryForm!: DateFormComponent;

  val: PatientAgeDOB = null;

  dobEntryMode: boolean = false;

  get placeholder(): string {
    const localizedTranslations = this.localeService.selectedLocaleDateTimeTranslations;
    let localizedPlaceholder = this.translate.instant('PLACEHOLDERS.PATIENT_AGE');

    localizedPlaceholder = localizedPlaceholder?.substring(0, localizedPlaceholder.indexOf('|') + 2);

    localizedTranslations?.ORDER.forEach((segment, i) => {
      let segmentKey = 'DATE.PLACEHOLDERS.' + segment;
      localizedPlaceholder += this.translate.instant(segmentKey);
      if (i != localizedTranslations.ORDER.length - 1) {
        localizedPlaceholder += this.dobLocalizedSeparator;
      }
    });

    return localizedPlaceholder;
  }

  get dobLocalizedSeparator(): string {
    const localizedTranslations = this.localeService.selectedLocaleDateTimeTranslations;

    // Update value for DOB date segment separator to current lab locale date separator
    return localizedTranslations.SEPARATOR;
  }

  ageDOBForm: FormGroup;
  dobEntryFormSub: Subscription;

  get value() {
    return this.val;
  }

  set value(val) {
    this.val = val;
    this.onChange(this.val);
    this.onTouched();
  }

  constructor(
    public translate: TranslateService,
    public dateFormService: DateFormService,
    private localeService: LocaleService
  ) {}

  @HostListener('paste', ['$event'])
  onPaste($event): void {
    const clipboardData = $event.clipboardData.getData('Text');

    if (StringUtil.hasSpecial(clipboardData)) {
      this.enableDOBEntryMode();

      setTimeout(() => {
        // Update date form value if clipboardData is in yyyy-mm-dd format
        if (DateTimeUtil.isValidDate(clipboardData)) {
          this.ageDOBForm.controls.dobEntryForm.setValue(clipboardData);
        } else {
          if (StringUtil.hasSpecial(clipboardData)) {
            this.dobEntryForm.handleDateWithSpecialCharacters(clipboardData);
          }
        }
      }, 0);

      setTimeout(() => {
        if (this.dobEntryForm && this.dobEntryForm.segmentInputs) {
          this.dobEntryForm.segmentInputs.last.handleFocus();
        }
      }, 0);
    }
  }

  ngOnInit() {
    this.ageDOBForm = new FormGroup({
      ageInput: new FormControl('', []),
      dobEntryForm: new FormControl('', []),
    });

    this.ageDOBForm.controls.ageInput.valueChanges.subscribe((val) => {
      if (val) {
        if (val.indexOf(this.dobLocalizedSeparator) === -1) {
          this.writeAgeValue(val);
        } else {
          this.writeDOBValue(val);
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.dobEntryFormSub) {
      this.dobEntryFormSub.unsubscribe();
    }
  }

  // When the input has a number value and the user hits Enter, add an age segment
  addAgeSegment() {
    let updatedValue = this.val;

    if (!updatedValue) {
      const newPatientAge: PatientAge = {
        years: 0,
        months: 0,
        weeks: 0,
        days: 0,
      };

      updatedValue = new PatientAgeDOB(newPatientAge, null, this.translate);
    }

    const inputValue = this.ageDOBForm.controls.ageInput.value.trim();
    const ageKeys: string[] = updatedValue.getAgeKeysWithoutValues();

    if (StringUtil.isNumber(inputValue) && ageKeys[0]) {
      updatedValue.updateAge(ageKeys[0], Number.parseInt(inputValue, 10));

      this.ageDOBForm.controls.ageInput.setValue('');

      this.value = updatedValue;
    }
  }

  removeAgeSegment(removeSegment?) {
    if (this.ageDOBForm.disabled) {
      return;
    }

    if (this.val) {
      const segmentsWithValues = this.val.getAgeKeysWithValues();

      if (!this.ageDOBForm.controls.ageInput.value && segmentsWithValues.length > 0) {
        const updatedValue = this.val;

        if (removeSegment) {
          updatedValue.updateAge(removeSegment.ageKey, 0);
        } else {
          const removeSegmentKey = segmentsWithValues[segmentsWithValues.length - 1];
          updatedValue.updateAge(removeSegmentKey, 0);
        }

        this.onValueChange(updatedValue);
      }
    }
  }

  handleBlur() {
    this.ageDOBForm.controls.ageInput.setValue('');
    this.onChange(this.val);
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  onValueChange(value: PatientAgeDOB, emitChange = true) {
    if (emitChange) {
      this.value = value;
    } else {
      this.val = value;
    }
  }

  writeValue(value: PatientAgeDOB): void {
    if (value == null) {
      this.reset();
    } else {
      if (DateTimeUtil.isValidDate(value.dateOfBirth)) {
        this.writeDOBValue(value.dateOfBirth, false, true);
      } else {
        this.onValueChange(value, false);
      }
    }
  }

  writeAgeValue(val: string, emitChange = true) {
    let updatedValue = this.val;

    if (!updatedValue) {
      updatedValue = new PatientAgeDOB(null, null, this.translate);
    }

    val = val.toUpperCase();

    const inputValueChars = val.split('');
    const ageLocalizedSegmentLabels = updatedValue.ageSegmentLabels();

    let nextSegmentIndex = 0;

    inputValueChars.forEach((valueChar, index) => {
      // Parse input value for entered age segments
      if (ageLocalizedSegmentLabels.find((segmentLabel) => segmentLabel === valueChar)) {
        if (this.dobEntryMode) {
          this.enableBaseEntryMode();
        }

        const segmentValue = val.substring(nextSegmentIndex, index);

        const updateKey = updatedValue.getAgeKeyFromLabel(valueChar);

        if (updateKey) {
          updatedValue.updateAge(updateKey, parseInt(segmentValue, 0));
        }

        nextSegmentIndex = index + 1;

        this.ageDOBForm.controls.ageInput.patchValue('');

        this.onValueChange(updatedValue, emitChange);
      }
    });
  }

  writeDOBValue(val: string, emitChange = true, validDate: boolean = false) {
    // Toggle DOB date form display
    if (!this.dobEntryMode) {
      this.enableDOBEntryMode(emitChange);
    }

    // after we've switched modes to show the age-form component we need to insert the value we entered within the age input
    setTimeout(() => {
      if (!validDate) {
        const firstDateSegment = this.dobEntryForm.order[0];

        // Patch first date segment input value
        this.dobEntryForm.dateForm.controls[firstDateSegment].patchValue(parseInt(val, 0));

        // Focus second date segment input
        this.dobEntryForm.segmentInputs.find((item, index) => index === 1).input.nativeElement.focus();

        this.onValueChange(new PatientAgeDOB(null, null, this.translate), emitChange);
      } else {
        this.ageDOBForm.controls.dobEntryForm.setValue(val);
        this.onValueChange(new PatientAgeDOB(null, val, this.translate), emitChange);
        this.dobEntryForm.dateForm.updateValueAndValidity();
      }
    }, 0);
  }

  enableDOBEntryMode(emitChange = true) {
    this.reset(emitChange);

    this.dobEntryMode = true;

    setTimeout(() => {
      this.dobEntryFormSub = this.dobEntryForm.dateForm.valueChanges.subscribe((val) => {
        const updatedValue = this.val;

        if (!val.month && !val.date && !val.year) {
          // DOB form empty, toggle back to base entry mode
          this.enableBaseEntryMode();
        }

        if (updatedValue) {
          const formattedDateOfBirth = this.dateFormService.getFormattedDate(val);

          updatedValue.dateOfBirth = formattedDateOfBirth;

          this.onValueChange(updatedValue, true);
        }
      });
    }, 0);
  }

  enableBaseEntryMode() {
    if (this.dobEntryFormSub) {
      this.dobEntryFormSub.unsubscribe();
    }

    this.reset();

    requestAnimationFrame(() => {
      if (this.ageInput) {
        this.focusAgeInput();
      }
    });
  }

  focusAgeInput() {
    this.ageInput.nativeElement.focus();
  }

  // Prevent special characters as first entry, disallow letters if not included in known age segment labels
  handleKeydown($event) {
    const key = $event.key.toUpperCase();

    let updatedValue = this.val;

    if (!updatedValue) {
      updatedValue = new PatientAgeDOB(null, null, this.translate);
    }

    const ageLocalizedSegmentLabels = updatedValue.ageSegmentLabels();

    const isDisallowedLetter =
      ageLocalizedSegmentLabels.indexOf(key) < 0 && StringUtil.isAlpha(key) && key.trim().length === 1;

    const disallowedEntry =
      this.ageDOBForm.controls.ageInput.value.trim().length < 1 &&
      ((key.trim().length === 1 && StringUtil.isAlpha(key)) ||
        StringUtil.hasSpecial(key) ||
        isDisallowedLetter ||
        isDisallowedLetter);

    if (!this.dobEntryMode && disallowedEntry && !$event.ctrlKey && !$event.altKey) {
      $event.preventDefault();
      $event.stopImmediatePropagation();
      return;
    }
  }

  reset(emitChange = true) {
    // Reset form inputs
    this.ageDOBForm?.controls?.dobEntryForm.patchValue('');
    this.ageDOBForm?.controls?.ageInput.patchValue('');

    if (this?.dobEntryForm?.dateForm) {
      this.dobEntryForm.dateForm.reset();
    }

    // Reset component value
    this.onValueChange(null, emitChange);
    this.dobEntryMode = false;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.ageDOBForm?.disable() : this.ageDOBForm?.enable();
  }

  // All validations handled in parent form
  validate(control: AbstractControl): ValidationErrors | null {
    const _date = control.value;

    if (control.dirty) {
      let errMsg = 'ERRORS_AND_FEEDBACK.INVALID_DATE';

      if (_date && this.dobEntryForm && this.dobEntryForm.hasIncompleteDate()) {
        return {
          invalid: true,
          _error: { message: errMsg, value: true },
        };
      }

      // future date
      errMsg = 'ERRORS_AND_FEEDBACK.FUTURE_DATE';

      if (DateTimeUtil.isFutureDate(this?.value?.dateOfBirth)) {
        return {
          invalid: true,
          _error: { message: errMsg, value: true },
        };
      }

      // greater than years
      errMsg = 'ERRORS_AND_FEEDBACK.OLDER_THAN_YEARS';

      let dob;

      if (this.dobEntryForm) {
        dob = this?.value?.dateOfBirth;
      }

      const ageSegments = this?.value?.age;

      let _olderThanYears = false;

      if (dob) {
        _olderThanYears = DateTimeUtil.greaterThanYears(dob, this.boundaryYears);
      } else if (ageSegments) {
        const _estimatedBirthdate = DateTimeUtil.estimateDateFromPeriod(ageSegments);
        // Outlandish entries can trip up our date-time library when processing date subtraction
        // (e.g. Estimating a birthdate for a patient born 5000 years ago)
        // In these cases the estimated date will be a future date
        _olderThanYears =
          DateTimeUtil.greaterThanYears(_estimatedBirthdate, this.boundaryYears) ||
          DateTimeUtil.isFutureDate(_estimatedBirthdate);
      }

      if (_olderThanYears && errMsg) {
        return {
          invalid: true,
          _error: {
            message: {
              text: errMsg,
              args: {
                value: this?.boundaryYears,
                name: 'Date',
              },
            },
          },
        };
      }

      return null;
    } else {
      return null;
    }
  }
}
